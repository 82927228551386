import {fetchSafeDocumentFragment} from './fetch'
import {isEditMode} from './user-status'
import {observe} from 'selector-observer'
import {remoteForm} from '@github/remote-form'

export {init} from './user-status'

remoteForm('.js-user-status-form', async function (form, wants) {
  const formGroup = form.querySelector<HTMLElement>('.js-user-status-form-group')!
  formGroup.classList.remove('errored')

  let response
  try {
    response = await wants.html()
  } catch (responseError) {
    formGroup.classList.add('errored')
    return
  }

  const oldContainer = form.closest<HTMLElement>('.js-user-status-container')!
  const newContainer = response.html.querySelector('.js-user-status-container')
  oldContainer.replaceWith(response.html)

  const otherContainers = document.querySelectorAll('.js-user-status-container')
  for (const otherContainer of otherContainers) {
    if (newContainer !== otherContainer) {
      const context = otherContainer.closest('.js-user-status-context')

      if (context) {
        const url = context.getAttribute('data-url')
        if (!url) throw new Error('could not get url')
        const fragment = await fetchSafeDocumentFragment(document, url)

        otherContainer.replaceWith(fragment)
      }
    }
  }
})

function toggleUserStatusEditHoverEffects(container: Element, show: boolean) {
  if (isEditMode(container)) {
    // Don't show mouseover effects if we're already in edit mode
    return
  }

  const elements = container.querySelectorAll('.js-toggle-user-status-edit.Details-content--closed')
  for (const el of elements) {
    el.classList.toggle('color-fg-accent', show)
    el.classList.toggle('Link--primary', !show)
  }
}

function showUserStatusEditHoverEffects(event: Event) {
  if (!(event.target instanceof Element)) return

  const container = event.target.closest<HTMLElement>('.js-user-status-container')!
  toggleUserStatusEditHoverEffects(container, true)
}

function hideUserStatusEditHoverEffects(event: Event) {
  if (!(event.target instanceof Element)) return

  const container = event.target.closest<HTMLElement>('.js-user-status-container')!
  toggleUserStatusEditHoverEffects(container, false)
}

observe('.js-toggle-user-status-edit', function (el) {
  el.addEventListener('mouseenter', showUserStatusEditHoverEffects)
  el.addEventListener('mouseleave', hideUserStatusEditHoverEffects)
})
